<template>
  <v-container>
    <!--{{ pixels }}-->
    <!-- <v-btn color="primary" @click="calis">Calis</v-btn> -->
    <v-row>
      <v-col cols="12">
        <v-card class="hidden-sm-and-down" elevation="10">
          <v-card-title>Inventario Activo
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              color="black"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            multi-sort
            light
            dense
            :items-per-page="10"
            :headers="headers"
            :items="activePixels"
            :search="search"
            :item-class="discrepancyRow"
          >

            <template v-slot:[`item.created`]="{ item }">
                {{new Date(parseInt(item.created)).toLocaleDateString()}} - {{new Date(parseInt(item.created)).toLocaleTimeString()}}
            </template>

            <template v-slot:[`item.lastFittingRoom`]="{ item }">
                <span v-if="item.fittingRoomCount > 0">{{new Date(parseInt(item.lastFittingRoom)).toLocaleDateString()}} - {{new Date(parseInt(item.lastFittingRoom)).toLocaleTimeString()}}</span>
                <span v-if="item.fittingRoomCount == 0">NA</span>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-card class="hidden-sm-and-down" elevation="10">
          <v-card-title>Probador
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchFittingRoom"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              color="black"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            multi-sort
            light
            dense
            :items-per-page="10"
            :headers="headersFittingRoom"
            :items="fittingRoomOrderByCreated"
            :search="searchFittingRoom"
          >

            <template v-slot:[`item.created`]="{ item }">
                {{new Date(parseInt(item.created)).toLocaleDateString()}} - {{new Date(parseInt(item.created)).toLocaleTimeString()}}
            </template>


          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-card class="hidden-sm-and-down" elevation="10">
          <v-card-title>Vendidos
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              color="black"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            multi-sort
            light
            dense
            :items-per-page="10"
            :headers="headersSold"
            :items="soldItems"
            :search="search"
          >

            <template v-slot:[`item.created`]="{ item }">
                {{new Date(parseInt(item.created)).toLocaleDateString()}} - {{new Date(parseInt(item.created)).toLocaleTimeString()}}
            </template>

            <template v-slot:[`item.lastFittingRoom`]="{ item }">
                <span v-if="item.fittingRoomCount > 0">{{new Date(parseInt(item.lastFittingRoom)).toLocaleDateString()}} - {{new Date(parseInt(item.lastFittingRoom)).toLocaleTimeString()}}</span>
                <span v-if="item.fittingRoomCount == 0">NA</span>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-card class="hidden-sm-and-down" elevation="10">
          <v-card-title>Inventario No Activo
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              color="black"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            multi-sort
            light
            dense
            :items-per-page="10"
            :headers="headers"
            :items="noActivePixels"
            :search="search"
          >

            <template v-slot:[`item.created`]="{ item }">
                {{new Date(parseInt(item.created)).toLocaleDateString()}} - {{new Date(parseInt(item.created)).toLocaleTimeString()}}
            </template>

            <template v-slot:[`item.lastFittingRoom`]="{ item }">
              <span v-if="item.fittingRoomCount > 0">{{new Date(parseInt(item.lastFittingRoom)).toLocaleDateString()}} - {{new Date(parseInt(item.lastFittingRoom)).toLocaleTimeString()}}</span>
              <span v-if="item.fittingRoomCount == 0">NA</span>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      search: '',
      searchFittingRoom: '',
      headers: [
        { text: 'Asset', value: 'assetId' },
        { text: 'SKU', value: 'sku' },
        { text: 'Modelo', value: 'modelo' },
        { text: 'Talla', value: 'talla' },
        { text: 'Color', value: 'color' },
        { text: 'Sexo', value: 'sexo' },
        { text: 'Hora', value: 'created' },
        { text: 'Estado', value: 'value' },
        { text: 'Ubicación Sistema', value: 'locationSystem' },
        { text: 'Ubicación Real', value: 'locationWiliot' },
        { text: 'Probador', value: 'fittingRoomCount' },
        { text: 'Visita Probador', value: 'lastFittingRoom' }
        
        //{ text: 'Confidence', value: 'confidence' }
      ],
      headersSold: [
        { text: 'Asset', value: 'assetId' },
        { text: 'SKU', value: 'sku' },
        { text: 'Modelo', value: 'modelo' },
        { text: 'Talla', value: 'talla' },
        { text: 'Color', value: 'color' },
        { text: 'Sexo', value: 'sexo' },
        { text: 'Hora Venta', value: 'created' },
        { text: 'Ubicación Sistema', value: 'locationSystem' },
        { text: 'Ubicación Real', value: 'locationWiliot' },
        { text: 'Probador', value: 'fittingRoomCount' },
        { text: 'Visita Probador', value: 'lastFittingRoom' }
        
        //{ text: 'Confidence', value: 'confidence' }
      ],
      headersFittingRoom: [
        { text: 'Asset', value: 'assetId' },
        { text: 'SKU', value: 'sku' },
        { text: 'Hora', value: 'created' },
      ]
    }
  },

  computed: {
    ...mapState(['catalog','pixels','sold','fittingRoom']),

    pixelsComplete () {
      if(this.pixels.length>0){
        //const pixelsSold = [...new Set(this.sold.map(e => e.assetId))]
        //const newPixels = this.pixels.filter(e => !pixelsSold.includes(e.assetId))
        return this.pixels.map(pixel => {
          const skuItem = this.catalog.find(({sku}) => sku == pixel.sku)
          const newProps = {
            color : skuItem.color,
            modelo : skuItem.modelo,
            sexo : skuItem.sexo,
            talla : skuItem.talla,
          }
          return Object.assign(pixel, newProps)
        })
      } else {
        return []
      }
    },

    activePixels () {
      if(this.pixelsComplete.length>0){
        return this.pixelsComplete.filter(item => item.value == 1 && item.locationSystem != 'NO ASIGNADO')
      } else {
        return []
      }
    },

    noActivePixels () {
      if(this.pixelsComplete.length>0){
        return this.pixelsComplete.filter(item => item.value == 0 || !item.value || item.locationSystem == 'NO ASIGNADO')
      //return this.pixelsComplete.filter(item => (item.value == 0 && item.confidence < 0.95) && item.value)
      } else {
        return []
      }
    },

    soldItems () {
      if(this.sold.length>0){
        //const pixelsSold = [...new Set(this.sold.map(e => e.assetId))]
        //const newPixels = this.pixels.filter(e => !pixelsSold.includes(e.assetId))
        return this.sold.map(pixel => {
          const skuItem = this.catalog.find(({sku}) => sku == pixel.sku)
          const newProps = {
            color : skuItem.color,
            modelo : skuItem.modelo,
            sexo : skuItem.sexo,
            talla : skuItem.talla,
          }
          console.log(newProps)
          return Object.assign(pixel, newProps)
        })
      } else {
        return []
      }
    },

    fittingRoomOrderByCreated () {
      return this.fittingRoom.sort((a,b) => (a.created > b.created) ? -1 : ((b.created < a.created) ? 1 : 0))
    }

  },

  methods: {
    ...mapActions(['getCatalog','getPixels','getSold','getFittingRoom']),

    calis () {
      const calisSold = [...new Set(this.sold.map(e => e.assetId))]
      const newPixels = this.pixels.filter(e => !calisSold.includes(e.assetId))
      console.log(newPixels)
    },
    
    discrepancyRow (item) {
      return (item.locationWiliot == 'ALMACEN' || item.locationWiliot == 'TIENDA') && (item.locationWiliot != item.locationSystem)  ? 'discrepancy' : ''
    },
  },

  

  created() {
    this.getCatalog()
    this.getPixels()
    this.getFittingRoom()
    this.getSold()
  },


}
</script>

<style >
.discrepancy {
  background-color: yellow;
}
</style>
